@font-face {
  font-family: "SFProdisplay";
  src: local("SFProdisplay"),
    url("./fonts/sf_pro_display.ttf") format("opentype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./fonts/Roboto.ttf") format("opentype");
}

.actionfilter_box {
  box-sizing: border-box;
  height: 25px;
  border-radius: 8px;
  padding: 4px;
  width: max-content;
  /* margin-top: 10px; */
}

.actionowner_box {
  box-sizing: border-box;
  width: 150px;
  height: 30px;
  border-radius: 8px;
  padding: 8px;
}

/* search box */
.searchBar {
  width: 300px;
  height: 40px;
  background: #ffffff;
  border-radius: 34px;
  margin-right: 15px;
}

/* search icon */
.searchIconWrapper {
  width: 50px;
  height: 40px;
  color: #005724;
  position: absolute;
  /* pointer-events: none; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.count {
  margin-top: 2px;
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'ekmukta';
  background: #005724;
  border-radius: 50%;
  color: #FFF;
}
