@font-face {
  font-family: "SFProdisplay";
  src: local("SFProdisplay"),
    url(../styles/fonts/sf_pro_display.ttf) format("opentype");
}

.top_label {
  height: 14px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 14px;
  color: #000000;
}

.table_label {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #000000;
}

.moreAction_box {
  box-sizing: border-box;
  width: 110px;
  height: 30px;
  background: #ececec;
  border-radius: 5px;
  padding-bottom: 0;
}

.moreactionbtn {
  width: 115px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  color: #000000;
}

.detailsbox {
  width: 970px;
  height: 45px;
  background: #ffffff;
  border: 4px solid #f3f3f3;
  border-radius: 5px;
}

.reminderinfobox {
  box-sizing: border-box;
  /* width: 1132px; */
  height: 30px;
  background: rgba(255, 154, 1, 0.2);
  border-radius: 7.56957px;
  margin-bottom: 10px;
}

.reminderlabel {
  width: 77px;
  height: 14px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.reminderdate {
  height: 14px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-left: 50px;
}

.amountlabel {
  width: auto;
  height: 14px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 14px;
  color: #000000;
}

.otherlabel {
  width: 88px;
  height: 12px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.amountbox {
  box-sizing: border-box;
  /* width: 1132px; */
  /* height: 55px auto; */
  border: 1px solid #dfdfdf;
  border-radius: 7.56957px;
}

.rupeeslabel {
  height: 14px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-bottom: 5px;
}

.percentagelabel {
  width: 25px;
  height: 14px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-bottom: 5px;
}

.flex-container {
  display: flex;
  /* background-color: DodgerBlue; */
}

.flex-container > div {
  /* background-color: #f1f1f1; */
  width: 80px;
  margin: 10px;
  text-align: center;
  font-size: 10px;
}

.tableData {
  width: 42px;
  height: 14px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #000000;
}

.tableDataHead {
  width: 80px;
  height: 12px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
}

.amountTablebox {
  display: flex;
  justify-content: "space-between";
}

.amountValue {
  width: 42px;
  height: 14px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #000000;
  text-align: center;
}

.amountValueLabel {
  width: 110px;
  height: 12px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  color: #000000;
}

.menulabel {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: "10px";
  color: #000000;
  line-height: 35px;
  margin-left: 10px;
}

.sub_menu_icons {
  height: 22px;
  width: 20px;
  border-radius: 0px;
  margin-left: 10px;
  color: #005724;
}

.clearAll {
  background: #005724;
  display: flex;
  justify-content: flex-end;
}

.card_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.pieData {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  color: #000000;
  margin-right: "13px";
}

.breadTitle {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #000000;
  cursor: pointer;
}
.reco_title:last-child {
  padding-bottom: 10px;
}
.leftbackarrow {
  margin-top: 10px;
  cursor: pointer;
}

.action_heading {
  width: 100px;
  height: 45px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #005724;
}
